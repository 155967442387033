import "./css/App.css";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import {default as Soiree2024Checkin} from "./Soiree2024/CheckinComponent";
import Auth from "./Auth";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token")

  useEffect(() => {
    if (!token) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      console.log(`/login?${currentUrl.searchParams}`);

      if (!location.pathname.includes("login")) {
        navigate(`/login?${currentUrl.searchParams}`);
      }
      
    }
  }, [token, location, navigate]);


  return (
    <div className="App">
      <Helmet>
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Soiree2024Checkin page="check-in" />} />
        <Route path="/check-in" element={<Soiree2024Checkin page="check-in" />} />
        <Route path="/dashboard" element={<Soiree2024Checkin page="dashboard" />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<Soiree2024Checkin />} />
      </Routes>
    </div>
  );
}

export default App;