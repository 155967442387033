import React, { Component } from "react";
import { checkin, allCheckins } from "./checkin_service";
import Select from "react-select";
import "../css/CheckinComponent.css"; // Create a separate CSS file for styling

import { Button, ButtonGroup, Container } from "react-bootstrap";

class CheckinForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      identifier: "",
      message: props.menuItems[props.selectedType].welcome,
      chairNum: "Checkin Desk 1",
      allAttendees: [],
      step: 1,
      selectedAttendee: null,
    };

    this.identifierInput = React.createRef();
  }

  componentDidMount() {
    this.preloadAttendees();

    // Focus the input element when the component mounts
    if (this.identifierInput.current) {
      this.identifierInput.current.focus();
    }

    // Parse the query string from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const chairNum = urlParams.get("chair_num");

    // Set the chairNum in the state if it exists
    if (chairNum) {
      this.setState({ chairNum });
    }
  }

  preloadAttendees = async () => {
    this.props.setIsLoading(true);
    const allAttendees = await allCheckins();

    this.setState({ allAttendees }, () => {
      this.props.setIsLoading(false);
    });
  };

  getAttendeeOptions = () => {
    return this.state.allAttendees
      .filter(
        (att) => att.barcode && att.attendee_firstname && att.attendee_lastname
      )
      .map((att) => ({
        value: att.barcode,
        label: `${att.attendee_firstname} ${att.attendee_lastname}`,
      }));
  };

  handleSelectAttendee = (selectedOption) => {
    const attendee = this.state.allAttendees.find(
      (att) => att.barcode === selectedOption.value
    );
    this.setState({
      selectedAttendee: attendee,
      identifier: selectedOption.value,
    });
  };

  getAttendeeByBarcode = (barcode) => {
    if (!barcode) {
      return null;
    }

    const { allAttendees } = this.state;
    const attendee = allAttendees.find((att) => att.barcode === barcode);
    return attendee || null;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.setIsLoading(true);

    if (this.state.step === 1) {
      await this.handleSubmit_Step_1();
    } else if (this.state.step === 2) {
      await this.handleSubmit_Step_2(e);
    }

    this.props.setIsLoading(false);
  };

  handleSubmit_Step_1 = async () => {
    let message = "";
    let step = 1;

    const attendee = this.getAttendeeByBarcode(this.state.identifier);

    if (attendee) {
      checkin(this.state.identifier, this.state.chairNum);

      message = (
        <span className="message success">
          Successfully checked in {attendee.attendee_firstname}{" "}
          {attendee.attendee_lastname}!<br />
          Paddle Number:{" "}
          {attendee.paddle_number
            ? attendee.paddle_number
            : "None Assigned"}{" "}
          and Seat: TBA <br />
          Proceed to process the next attendee.
        </span>
      );
    } else {
      message = (
        <span className="message info">Please search for name of attendee</span>
      );
      step = 2;
    }

    this.setState({
      message: message,
      needMoreInfo: false,
      identifier: "",
      step,
      selectedAttendee: null
    });
  };

  handleSubmit_Step_2 = async (e) => {
    const { selectedAttendee } = this.state;

    if (selectedAttendee) {
      this.handleSubmit_Step_1();
    } else {
      this.handleCantFindAttendee(e);
    }
  };

  handleSubmit_Step_3 = async () => {
    const message = (
      <span className="message info">
        Attendee needs to complete registration. Tell attendee to proceed to the
        Help Desk.
      </span>
    );
  };

  handleInputChange = (id, value) => {
    this.setState({
      [id]: value,
    });
  };

  handleCantFindAttendee = (e) => {
    e.preventDefault();
    // Implement the functionality for "Can't Find Attendee" button
    this.setState({
      identifier: "",
      step: 1,
      message: (
        <span className="message info">
          Please send attendee to the Help Desk and proceed with the next attendee.
        </span>
      ),
    });
  };

  customStyles = {
    control: (provided) => ({
      ...provided,
      height: "auto",
      fontSize: "3rem"
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
    }),
  };

  render() {
    const { step, chairNum, selectedAttendee } = this.state;
    return (
      <>

        <div className="auth-wrap">
          <div className="auth-col pledges">
          <h1 style={{ color: "#4b6584" }}>
          <span>{chairNum}</span>
          {this.props.titleSuffix}
        </h1>
        <p className="message-well">{this.state.message}</p>

            <form className="form-validate" onSubmit={this.handleSubmit}>
              {step === 1 && (
                <>
                  <div className="contactform-field contactform-textarea">
                    <label className="contactform-label">Barcode</label>
                    <span className="contactform-input">
                      <input
                        ref={this.identifierInput}
                        className="pledge-input"
                        type="text"
                        name="identifier"
                        data-required="text"
                        value={this.state.identifier}
                        onChange={(e) =>
                          this.setState({ identifier: e.target.value })
                        }
                      />
                    </span>
                  </div>

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        className="custom-submit-button"
                      >
                        No Ticket?
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Check In
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="contactform-field contactform-textarea dropdown">
                    <label className="contactform-label">Search Attendee</label>
                    <span className="contactform-input">
                      <Select
                        options={this.getAttendeeOptions()}
                        onChange={this.handleSelectAttendee}
                        value={
                          selectedAttendee
                            ? {
                                value: selectedAttendee.barcode,
                                label: `${selectedAttendee.attendee_firstname} ${selectedAttendee.attendee_lastname}`,
                              }
                            : null
                        }
                        placeholder="Select an attendee"
                        styles={this.customStyles}
                      />
                    </span>
                  </div>

                  <div className="auth-submit">
                    <div className="button-group">
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        className="custom-submit-button"
                        onClick={this.handleCantFindAttendee}
                      >
                        Can't find?
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        className="custom-submit-button submit-button"
                      >
                        Check In
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </form>

            {this.state.error && (
              <div className="err404">
                <p className="err404-search">{this.state.error}</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default CheckinForm;